$(document).ready(function () {
    $('#debt').click(function () {
        $('.modal').addClass('modal--is-open')
        $('body').addClass('modal-is-open')
    })
    $('.modal').click(function (e) {
        $('.modal').removeClass('modal--is-open')
        $('body').removeClass('modal-is-open')
    })
    .on('click', '.modal__container', function (e) {
        e.stopPropagation()
    })
    $('.modal__close-button').click(function () {
        $('.modal').removeClass('modal--is-open')
        $('body').removeClass('modal-is-open')
    })
})